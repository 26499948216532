import type { Config } from 'tailwindcss'

import BreakOutPlugin from 'tailwind-container-break-out'
import ScrollbarHidePlugin from 'tailwind-scrollbar-hide'
import FlowbitePlugin from 'flowbite/plugin'

const tailwindConfig: Config = {
  content: [
    './node_modules/flowbite-react/lib/**/*.js',
    './src/pages/**/*.{js,ts,jsx,tsx,mdx}',
    './src/components/**/*.{js,ts,jsx,tsx,mdx}',
    './src/app/**/*.{js,ts,jsx,tsx,mdx}',
  ],
  safelist: [
    {
      pattern: /line-clamp-\d+/,
    },
  ],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    accentColor: '#895ad7',
    ringColor: '#895ad7',
    outlineColor: '#895ad7',
    screens: {
      sm: '768px',
      md: '1008px',
      lg: '1280px',
      xl: '1536px',
      '2xl': '1728px',
    },
    extend: {
      backgroundImage: {
        'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
        'gradient-conic':
          'conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))',
        'course-suggestions-banner': 'url(/images/mydra-banner-photo.png)',
        newsletter: 'url("/images/newsletter-image.png")',
        'gradient-course-nag-bar':
          'linear-gradient(90deg, #E6DEFF 6.71%, #FBE870 47.42%, #E6DEFF 91.67%);',
        'mydra-bg': 'url(/images/mydra-bg.png)',
        'enrolment-card-bg':
          'linear-gradient(180deg, rgba(51, 36, 76, 0.00) 17.22%, #33244C 100%)',
        'sf-check': 'url(/images/sf-check.png)',
        'landing-page-course-card-bg':
          'linear-gradient(180deg, rgba(17, 17, 17, 0.16) 22.94%, rgba(17, 17, 17, 0.80) 100%)',
        'partner-stats-hero-bg1':
          'linear-gradient(230deg, #CFB9FE 13.19%, #E2D8D0 44.58%, #F2BA94 86.19%);',
        'partner-stats-hero-bg2':
          'linear-gradient(230deg, #FCF2AD 13.19%, #C2C2C2 44.58%, #ABBAEB 86.19%);',
        'partner-stats-hero-bg3':
          'linear-gradient(197deg, #33244C 0.33%, #ABBAEB 54.59%, #F7F7FA 90.62%);',
      },
      padding: {
        42: '10.5rem',
      },
      colors: {
        beige: '#FFF9F1',
        black: '#292626',
        'course-card-border': '#dad4d3',
        'edp-green': '#28FF52',
        error: '#D20819',
        'fundae-orange': '#E3A522',
        'gray-light': '#5E636F',
        'grey-background': '#F7F7FA',
        'light-beige': '#F6F6F6',
        lines: '#e2e3e6',
        'modal-bg': 'rgba(51, 36, 76, 0.40);',
        'mydra-beige': '#E2D8D0',
        'mydra-black': '#111111',
        'mydra-blue': '#00ADEF',
        'mydra-coral': '#F2BA94',
        'mydra-dark-purple': '#33244c',
        'mydra-gray': '#F7F7F7',
        'mydra-light-background': '#FBF5EF',
        'mydra-light-beige': '#FBF5EF',
        'mydra-light-blue': 'rgba(171, 186, 235, 0.40)',
        'mydra-light-green': 'rgba(185, 224, 208, 0.40)',
        'mydra-light-orange': '#F2BA94',
        'mydra-light-purple': '#F3EFFF',
        'mydra-light-red': 'rgba(242, 186, 148, 0.40)',
        'mydra-light': '#FBF5EF',
        'mydra-lighter-orange-border': 'rgba(242, 186, 148, 0.60)',
        'mydra-lighter-orange': 'rgba(242, 186, 148, 0.20)',
        'mydra-lilas': '#CFB9FE',
        'mydra-medium-beige': '#E2D8D0',
        'mydra-medium-gray': '#C2C2C2',
        'mydra-medium-purple': '#CFB9FE',
        'mydra-mouse': '#C2C2C2',
        'mydra-orange': '#F97052',
        'mydra-purple-light': 'rgba(230, 222, 255, 0.20)',
        'mydra-purple': '#895ad7',
        'mydra-violet': '#E6DEFF',
        'mydra-white': '#ffffff',
        'mydra-yellow': '#FCF2AD',
        primary: 'rgba(137, 90, 215, 1)',
        ringColor: '#895ad7',
        'sf-bg-pink': '#E6DEFF',
      },
      dropShadow: {
        'card-active': '0px 2px 16px #E6DEFF',
        'card-active-on-slider': '0px 2px 16px rgba(47, 21, 95, .15)',
        'course-box': '0px 2px 16px #E2E3E6',
        'simulator-fieldset-legend': '0px 4px 4px rgba(94, 99, 111, 0.12)',
        toggle: [
          '0px 1px 3px rgba(0, 0, 0, 0.12)',
          '0px 1px 1px rgba(0, 0, 0, 0.14)',
          '0px 2px 1px rgba(0, 0, 0, 0.20)',
        ],
      },
      boxShadow: {
        'auto-complete': '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
        accordion: '0px 0px 0px 1px #e2e3e6 inset',
        'cohort-selector': '0px 4px 8px 0px rgba(94, 99, 111, 0.10)',
        prerequisites: '0px 4px 12px 0px rgba(94, 99, 111, 0.12);',
        'course-content-item': '0px 4px 4px 0px rgba(94, 99, 111, 0.15)',
        'simulator-fieldset-legend': '0px 4px 4px 0px rgba(94, 99, 111, 0.12)',
        tooltip: '0px 3px 6px 0px rgba(94, 99, 111, 0.17);',
      },
      gridTemplateColumns: {
        'course-page': '8fr 4fr',
        'desktop-profile-page': '460px 1fr',
        prerequisites: 'repeat(auto-fit, minmax(20%, 1fr));',
      },
      gridTemplateRows: {
        'fix-pay-calculator': '1fr fit-content(100%)',
      },
      borderRadius: {
        'rounded-2xl': '1rem',
      },
      fontFamily: {
        sans: ['var(--font-inter)'],
        title: ['var(--font-gt-planar)'],
      },
      fontSize: {
        xxxs: '0.5625rem',
        xxs: '.625rem',
        h1: '2.25rem',
        h2: '1.875rem',
        h3: '1.625rem',
        h4: '1.5rem',
        h5: '1rem',
        h6: '0.875rem',
        'body-pp': '1.5rem',
        'body-p': '1.125rem',
        'card-title': '1.125rem',
        'h1-mobile': '1.75rem',
        'h2-mobile': '1.5rem',
        'h3-mobile': '1.125rem',
        'body-p-mobile': '1rem',
      },
      aspectRatio: {
        'top-course-banner': '44/25',
        'course-card': '2',
      },
      lineHeight: {
        h1: '2.5rem',
        h2: '3.25rem',
        h3: '2.625rem',
        'h1-mobile': '2.125rem',
      },
      maxWidth: {
        'xl-breakpoint': '1560px',
      },
      ringColor: '#895ad7',
      container: {
        center: true,
        padding: {
          DEFAULT: '1.25rem',
          md: '2rem',
          lg: '3rem',
          xl: '3.5rem',
          '2xl': '5.25rem',
        },
      },
      keyframes: {
        translateVisibleToInvisible: {
          '0%': {
            transform: 'translateX(100%)',
          },
          '100%': {
            transform: 'translateX(0%)',
          },
        },
        slide: {
          '0%': { transform: 'translateX(0%)' },
          '100%': { transform: 'translateX(-200%)' }, // Changed from -50% to -200%
        },
      },
      animation: {
        notification: 'translateVisibleToInvisible .250s ease-in',
        slide: 'slide 20s linear infinite',
      },
    },
  },
  plugins: [BreakOutPlugin, ScrollbarHidePlugin, FlowbitePlugin],
}

export default tailwindConfig
