export interface TypographyProps<T> extends React.HTMLProps<T> {
  children?: React.ReactNode
  className?: string
  tag?: string
  as?: React.ElementType
}

interface EnhancedProps {
  tag: string
  classModifier?: string
}

const typographyHOC = <T extends {}>(options: EnhancedProps) => {
  const { tag, classModifier } = options

  const Typography: React.FunctionComponent<TypographyProps<T>> = ({
    children,
    className = '',
    as: Tag = tag,
    ...props
  }) => {
    const classes = `${classModifier} ${className}`

    return (
      <Tag className={`${classes} ${props?.className ?? ''}`} {...props}>
        {children}
      </Tag>
    )
  }

  return Typography
}

/**
 * @deprecated use Paragraph instead
 */
export const Body1 = typographyHOC<HTMLParagraphElement>({
  classModifier: 'md:text-body-p text-body-p-mobile',
  tag: 'p',
})

/**
 * @deprecated use Paragraph instead
 */
export const Body2 = typographyHOC<HTMLHeadingElement>({
  classModifier: 'text-body-p font-sans',
  tag: 'p',
})

/**
 * @deprecated use Paragraph instead
 */
export const Body3 = typographyHOC<HTMLHeadingElement>({
  classModifier: 'text-body-pp font-sans',
  tag: 'p',
})

/**
 * @deprecated use Paragraph instead
 */
export const Caption = typographyHOC<HTMLHeadingElement>({
  classModifier: 'uppercase text-[--text] font-semibold text-sm',
  tag: 'p',
})

export const Xl = typographyHOC<HTMLParagraphElement>({
  classModifier: 'font-sans text-[1.375rem] font-semibold leading-7',
  tag: 'p',
})

export const Lg = typographyHOC<HTMLParagraphElement>({
  classModifier: 'font-sans text-lg font-normal leading-7',
  tag: 'p',
})

export const Base = typographyHOC<HTMLParagraphElement>({
  classModifier: 'font-sans font-normal leading-6',
  tag: 'p',
})

export const Sm = typographyHOC<HTMLParagraphElement>({
  classModifier: 'font-sans text-sm font-normal leading-[130%]',
  tag: 'p',
})

export const Xs = typographyHOC<HTMLParagraphElement>({
  classModifier: 'font-sans text-xs font-normal leading-3',
  tag: 'p',
})

export const Paragraph = {
  Base,
  Normal: Base,
  Lg,
  Sm,
  Xs,
  Xl,
}

export { Base as P }

export const Headline1 = typographyHOC<HTMLHeadingElement>({
  classModifier:
    'font-title text-[1.5rem] md:text-[2.5rem] leading-[120%] tracking-[-0.08px]',
  tag: 'h1',
})

export const Headline2 = typographyHOC<HTMLHeadingElement>({
  classModifier:
    'font-title text-[1.25rem] tracking-[-0.025rem] md:tracking-normal md:text-[1.875rem] leading-[120%]',
  tag: 'h2',
})

export const Headline3 = typographyHOC<HTMLHeadingElement>({
  classModifier:
    'font-sans text-[1.375rem] font-semibold md:text-[1.875rem] leading-[120%] tracking-[-0.48px]',
  tag: 'h3',
})

export const Headline4 = typographyHOC<HTMLHeadingElement>({
  classModifier: 'font-title text-[1.5rem] leading-[120%] tracking-[-0.4px]',
  tag: 'h4',
})

export const Headline5 = typographyHOC<HTMLHeadingElement>({
  classModifier: 'font-sans text-[1.5rem] leading-[120%] font-semibold',
  tag: 'h5',
})

export const Headline6 = typographyHOC<HTMLHeadingElement>({
  classModifier: 'font-title text-[1.25rem] leading-[120%]',
  tag: 'h6',
})
