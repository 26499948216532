import Cookies from 'js-cookie'
import {
  PRIVATE_ROUTES,
  REDIRECT_TO_URL_PATH_REGEX,
  ROUTES,
  USER_INFO_COOKIE_NAME,
} from './constants'
import { IUser } from '@/types/user'

export function logout(redirectTo = '/') {
  if (
    window.location.pathname.includes(ROUTES.logout) ||
    window.location.pathname.includes(ROUTES.login)
  ) {
    return
  }

  window.location.href = `${ROUTES.logout}?redirectTo=${redirectTo}`
}

export function isPrivateRoute(route: string) {
  return PRIVATE_ROUTES.some((privateRoute: string | RegExp) => {
    if (privateRoute instanceof RegExp) {
      return privateRoute.exec(route)
    }

    return privateRoute === route
  })
}

export function isValidRedirect(path: string) {
  return (
    path &&
    ![
      ROUTES.login,
      ROUTES.authLogin,
      ROUTES.signup,
      ROUTES.accessDenied,
      ROUTES.notFound,
    ].includes(path) &&
    path.match(REDIRECT_TO_URL_PATH_REGEX)
  )
}

export function setUserCookie(user: IUser) {
  return Cookies.set(
    USER_INFO_COOKIE_NAME,
    JSON.stringify({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      _id: user._id ?? '',
      company: user.company ?? '',
      roles: user.roles ?? [],
    }),
    {
      httpOnly: false,
    }
  )
}
