import { ICompany } from './company'
import { IEducationProvider } from './education-provider'

export interface IUser {
  _id?: string
  firstName: string
  lastName: string
  email: string
  password: string
  isActive: boolean
  permissions: string[]
  roles: string[]
  createdAt: Date
  updatedAt: Date
  company?: ICompany | string | null
  linkedIn?: string
  jobRole?: string
  educationProvider?: IEducationProvider | string | null
}

export enum UserRole {
  SuperAdmin = 'superadmin',
  EducationProvider = 'educationprovider',
  User = 'user',
  Applicant = 'applicant',
  Employer = 'employer',
  TaxBenefitsProvider = 'taxbenefitsprovider',
}

export enum UserInclude {
  TaxBenefitProviders = 'taxBenefitProviders',
  Company = 'company',
  EducationProvider = 'educationProvider',
}
