import { getLangNameFromCode } from 'language-name-map'
import ChevronDown from '@/app/icons/ChevronDown/ChevronDown'
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { GlobeIcon } from '../../icons'
import { GB, ES, DE, IT, PT, FR } from 'country-flag-icons/react/3x2'
import React from 'react'
import { useLanguageSelection } from '../../providers/LanguageSelectionProvider'

const flagDefaultProps = {
  className: 'w-4 h-4',
}

const LOCALE_TO_FLAG = {
  en: <GB />,
  es: <ES />,
  de: <DE />,
  it: <IT />,
  pt: <PT />,
  fr: <FR />,
}

interface LanguageSelectorProps extends MenuButtonProps {
  variant?: 'select' | 'button'
}

export function LanguageSelector({
  variant = 'select',
  ...props
}: LanguageSelectorProps) {
  const { currentLocale, changeLanguage, availableLocales } =
    useLanguageSelection()

  if (availableLocales.length <= 1) {
    return null
  }

  const menuButtonContent =
    variant === 'button' ? (
      <>
        <svg className="w-4 h-4" viewBox="0 0 16 16" fill="none">
          <path
            d="M1 8C1 11.866 4.134 15 8 15C11.866 15 15 11.866 15 8C15 4.134 11.866 1 8 1C4.134 1 1 4.134 1 8Z"
            stroke="currentColor"
            strokeLinejoin="round"
          />
          <path
            d="M5 8H15M1 8H3M8 1C9.5 3 10 5 10 8C10 11 9.5 13 8 15C6.5 13 6 11 6 8C6 5 6.5 3 8 1Z"
            stroke="currentColor"
            strokeLinecap="round"
          />
        </svg>
        <span>{getLangNameFromCode(currentLocale)?.native}</span>
        <ChevronDown className="w-2 h-2 ml-1 rotate-180 group-data-[active]/button:rotate-0" />
      </>
    ) : (
      <>
        <GlobeIcon />
        {getLangNameFromCode(currentLocale)?.native}
        <ChevronDown className="w-2 h-2 ml-1 rotate-180 group-data-[active]/button:rotate-0" />
      </>
    )

  const menuButtonClassName =
    variant === 'button'
      ? 'items-center text-sm font-medium hidden space-x-2 text-gray-700 md:flex hover:text-gray-900 group/button'
      : `inline-flex items-center gap-1 rounded-lg bg-transparent py-1 px-2 text-sm leading-8 font-medium group/button text-white focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white ${props.className ?? ''}`

  return (
    <Menu>
      <MenuButton {...props} className={menuButtonClassName}>
        {menuButtonContent}
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          {...props}
          anchor="bottom end"
          modal={false}
          className="w-52 z-50 origin-top-right rounded-lg border bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none font-sans"
        >
          {availableLocales.map((locale) => (
            <MenuItem key={locale}>
              <button
                className="group flex w-full items-center gap-2 py-1.5 px-3 text-sm font-medium font-sans data-[focus]:bg-mydra-black/90 data-[focus]:text-white"
                onClick={() => changeLanguage(locale)}
              >
                {React.cloneElement(LOCALE_TO_FLAG[locale], flagDefaultProps)}
                {getLangNameFromCode(locale)?.native}
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  )
}
