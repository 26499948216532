import * as React from 'react'
import { SVGProps } from 'react'

const SettingsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="-0.5 -0.5 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      d="M5.625 7.5a1.875 1.875 0 1 0 3.75 0 1.875 1.875 0 1 0-3.75 0"
    />
    <path
      stroke="currentColor"
      d="M8.603 1.345c-.23-.095-.52-.095-1.103-.095-.582 0-.874 0-1.103.095a1.25 1.25 0 0 0-.677.677c-.058.14-.08.302-.09.54a1.01 1.01 0 0 1-.493.845 1.01 1.01 0 0 1-.98.005c-.21-.111-.361-.173-.511-.193a1.25 1.25 0 0 0-.925.248c-.197.151-.342.404-.634.908-.29.504-.436.757-.469 1.003a1.25 1.25 0 0 0 .248.924c.092.12.221.221.422.347.296.186.486.502.486.851s-.19.665-.486.85c-.2.127-.33.228-.422.348a1.25 1.25 0 0 0-.248.924c.033.246.178.499.47 1.003.29.504.436.757.633.908.263.202.596.29.925.248.15-.02.302-.082.512-.193a1.01 1.01 0 0 1 .979.005c.302.175.48.497.494.846.009.237.031.4.09.54.126.306.37.549.676.676.23.095.52.095 1.103.095.582 0 .874 0 1.103-.095.307-.127.55-.37.677-.677.058-.14.08-.302.09-.54a1.01 1.01 0 0 1 .493-.845 1.01 1.01 0 0 1 .98-.005c.21.111.361.173.511.192a1.25 1.25 0 0 0 .925-.247c.197-.151.342-.404.634-.908.29-.504.436-.757.469-1.003a1.25 1.25 0 0 0-.248-.924c-.092-.12-.221-.221-.422-.348-.296-.185-.486-.501-.486-.85s.19-.665.486-.85c.2-.127.33-.228.422-.348a1.25 1.25 0 0 0 .248-.924c-.032-.246-.178-.499-.47-1.003-.29-.504-.436-.757-.633-.908a1.25 1.25 0 0 0-.925-.248c-.15.02-.302.082-.512.193a1.01 1.01 0 0 1-.979-.005 1.01 1.01 0 0 1-.494-.846c-.009-.237-.031-.4-.09-.54a1.25 1.25 0 0 0-.676-.676Z"
    />
  </svg>
)
export default SettingsIcon
