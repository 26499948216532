import { boomify } from '@hapi/boom'

const cookieName = process.env.NEXT_PUBLIC_AUTH_COOKIE_NAME

export type RequestOptions = RequestInit & { plain?: boolean; raw?: boolean }

export class ApiService {
  static API_URL = process.env.NEXT_PUBLIC_API_URL ?? ''
  static DEFAULT_HEADERS = {
    'x-api-key': process.env.NEXT_PUBLIC_API_KEY ?? '',
    'x-mydra-app': 'marketplace',
  }
  static accessToken = ''
  static headers = {}

  static async get(endpoint: string, fetchOptions?: RequestOptions) {
    const url = new URL(endpoint, ApiService.API_URL)

    const res = await fetch(url, {
      headers: {
        ...ApiService.DEFAULT_HEADERS,
        ...fetchOptions?.headers,
        ...(ApiService.accessToken && {
          Cookie: `${cookieName}=${ApiService.accessToken}`,
        }),
        ...ApiService.headers,
      },
      credentials: 'include',
      ...fetchOptions,
    })

    if (!res.ok) {
      let json
      try {
        json = await res.json()
      } catch (e) {
        json = {}
      }

      throw boomify(new Error(json.message ?? res.statusText), {
        statusCode: res.status,
        data: json,
      })
    }

    if (fetchOptions?.raw) {
      return res
    }

    return res.json()
  }

  static async post(
    endpoint: string,
    payload: unknown,
    fetchOptions?: RequestOptions
  ) {
    const url = new URL(endpoint, ApiService.API_URL)
    const isMultiPartFormData =
      payload instanceof FormData || payload instanceof File
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        ...ApiService.DEFAULT_HEADERS,
        ...(isMultiPartFormData ? {} : { 'Content-Type': 'application/json' }),
        ...fetchOptions?.headers,
        ...(ApiService.accessToken && {
          Cookie: `${cookieName}=${ApiService.accessToken}`,
        }),
        ...ApiService.headers,
      },
      body: isMultiPartFormData ? payload : JSON.stringify(payload),
      credentials: 'include',
      ...fetchOptions,
    })

    if (!res.ok) {
      let json
      try {
        json = await res.json()
      } catch (e) {
        json = {}
      }

      throw boomify(new Error(json.message ?? res.statusText), {
        statusCode: res.status,
        data: json,
      })
    }

    if (fetchOptions?.plain) {
      return res.text()
    }

    return res.json()
  }

  static async patch(
    endpoint: string,
    payload: unknown,
    fetchOptions?: RequestOptions
  ) {
    const url = new URL(endpoint, ApiService.API_URL)
    const res = await fetch(url, {
      method: 'PATCH',
      headers: {
        ...ApiService.DEFAULT_HEADERS,
        'Content-Type': 'application/json',
        ...fetchOptions?.headers,
        ...(ApiService.accessToken && {
          Cookie: `${cookieName}=${ApiService.accessToken}`,
        }),
        ...ApiService.headers,
      },
      body: JSON.stringify(payload),
      credentials: 'include',
      ...fetchOptions,
    })

    if (!res.ok) {
      let json
      try {
        json = await res.json()
      } catch (e) {
        json = {}
      }

      throw boomify(new Error(json.message ?? res.statusText), {
        statusCode: res.status,
        data: json,
      })
    }
    return res.json()
  }

  static async put(
    endpoint: string,
    payload: unknown,
    fetchOptions?: RequestOptions
  ) {
    const url = new URL(endpoint, ApiService.API_URL)
    const isMultiPartFormData =
      payload instanceof FormData || payload instanceof File
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        ...ApiService.DEFAULT_HEADERS,
        ...(isMultiPartFormData ? {} : { 'Content-Type': 'application/json' }),
        ...fetchOptions?.headers,
        ...(ApiService.accessToken && {
          Cookie: `${cookieName}=${ApiService.accessToken}`,
        }),
        ...ApiService.headers,
      },
      body: isMultiPartFormData ? payload : JSON.stringify(payload),
      credentials: 'include',
      ...fetchOptions,
    })

    if (!res.ok) {
      let json
      try {
        json = await res.json()
      } catch (e) {
        json = {}
      }

      throw boomify(new Error(json.message ?? res.statusText), {
        statusCode: res.status,
        data: json,
      })
    }
    return res.json()
  }

  static setAccessToken(accessToken: string) {
    ApiService.accessToken = accessToken
  }

  static setHeader(headerName: string, headerValue) {
    ApiService.headers[headerName] = headerValue
  }
}
