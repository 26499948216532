import { useMyUser } from '@/app/hooks/data-hooks/useMyUser'
import { setUserCookie } from '@/app/lib/auth'
import { ROUTES, USER_INFO_COOKIE_NAME } from '@/app/lib/constants'
import { isBoom } from '@hapi/boom'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import React, { useMemo } from 'react'

type AuthContextArgs = {
  isAuthenticated: boolean
  _id: string | null
  firstName: string | null
}

type AuthContextValue = {
  isAuthenticated: boolean
  userId: string | null
  firstName: string | null
  lastName: string | null
  isLoading: boolean
  company: string | null
  roles: string[] | null
}

const AuthContext = React.createContext<AuthContextValue>({
  isAuthenticated: false,
  userId: null,
  firstName: null,
  lastName: null,
  isLoading: false,
  company: null,
  roles: null,
})

type AuthProviderProps = {
  children: React.ReactNode
  userInfo?: Pick<AuthContextArgs, 'firstName' | '_id'>
}

export const AuthProvider = ({ children, userInfo }: AuthProviderProps) => {
  const router = useRouter()

  const { data: { data: user } = {}, isLoading } = useMyUser({
    options: {
      enabled:
        router.isReady &&
        !Cookies.get(USER_INFO_COOKIE_NAME) &&
        [ROUTES.authLogin, ROUTES.logout].every(
          (route) => !router.pathname.includes(route)
        ),
      initialData: () => {
        try {
          if (userInfo) {
            return { data: userInfo }
          }

          const userInfoFromCookie = JSON.parse(
            Cookies.get(USER_INFO_COOKIE_NAME) ?? ''
          )
          return { data: userInfoFromCookie }
        } catch (error) {
          return undefined
        }
      },
      onSuccess: (data) => {
        const { data: user } = data ?? {}
        if (user) {
          setUserCookie(user)
        }
      },
      retry: (_failureCount, error) => {
        if (isBoom(error, 401)) {
          return false
        }
        return true
      },
    },
  })

  const memoizedValue = useMemo(() => {
    return {
      isAuthenticated: !!user,
      firstName: user?.firstName ?? null,
      lastName: user?.lastName ?? null,
      userId: user?._id ?? null,
      company: (user?.company as string) ?? null,
      roles: user?.roles ?? [],
      isLoading,
    }
  }, [user, isLoading])

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = React.useContext(AuthContext)

  return context
}

export function useIsAuthenticated() {
  const context = useAuth()
  return context.isAuthenticated
}
