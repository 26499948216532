import { createContext, useContext, ReactNode } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { getLangNameFromCode } from 'language-name-map'

type LanguageSelectionContextType = {
  currentLocale: string
  changeLanguage: (locale: string) => Promise<void>
  availableLocales: string[]
  getLangNameFromCode: typeof getLangNameFromCode
}

const LanguageSelectionContext = createContext<
  LanguageSelectionContextType | undefined
>(undefined)

export function LanguageSelectionProvider({
  children,
}: {
  children: ReactNode
}) {
  const router = useRouter()

  const changeLanguage = async (locale: string) => {
    try {
      Cookies.set('NEXT_LOCALE', locale, { expires: 365 })
    } catch {
      // ignore
    }
    await router.push(router.pathname, router.asPath, { locale })
    router.reload()
  }

  const value = {
    currentLocale: router.locale || 'en',
    changeLanguage,
    availableLocales: router.locales || [],
    getLangNameFromCode,
  }

  return (
    <LanguageSelectionContext.Provider value={value}>
      {children}
    </LanguageSelectionContext.Provider>
  )
}

export function useLanguageSelection() {
  const context = useContext(LanguageSelectionContext)

  if (context === undefined) {
    throw new Error(
      'useLanguageSelection must be used within a LanguageSelectionProvider'
    )
  }

  return context
}
